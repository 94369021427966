import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {LocaleContext} from "./layout";

const useTranslations = () => {
    // Grab the locale (passed through context) from the Context Provider
    const {locale} = React.useContext(LocaleContext);
    // Query the JSON files in <rootDir>/i18n/translations
    const {rawData} = useStaticQuery(query);

    // Simplify the response from GraphQL
    const simplified = rawData.edges.map((item) => {
        return {
            name: item.node.name,
            translations: item.node.translations,
        };
    });

    // Only return translations for the current locale
    const {translations} = simplified.filter((lang) => lang.name === locale)[0];

    return translations;
};

export default useTranslations;

const query = graphql`
	query useTranslations {
		rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
			edges {
				node {
					name
					translations: childTranslationsJson {
						backToHome
						news
						productInfo
						companyProfile
						history
						office
						isoCertificate
						jcssCertificate
						privacy
						companyInfo
						contact
						faq
						catalogDownload
						softDownload
						documents
						emissivity
						catchall
						manual
						topAnnounce
						customerSupport
						recruitment
						searchWord
						searchProducts
						events
						campaign
						exhibition
						allNews
						products
						newProducts
						notice
						agreeAndDownload
						disagreeAndNotDownload
						Downloading
						general
						seeall
						RequestaQuote
						PleaseUseTheInquiryForm
						NewGraduateRecruitmentGuidelines
						OurBasic
						SeeCompanyInformation
						ProductNotFound
						yen
						taxIncluded
						searchResults
						showDiscontinuedProducts
						pages
						casenumber
						transmittersAbout
						psychrometersAbout
						phMetersAbout
						forSk610ph2About
						forSk620phAbout
						forSk620ph2sk650phAbout
						jcss_accredited_calibration
						calibration
						catalog
						manual
						download
						PleaseUseIt
						catalogDownloadSK
						catalogDownloadSK2
						registration
						open_price
					}
				}
			}
		}
	}
`;
