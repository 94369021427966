import React from "react";
import Footer from "../../../../../../components/Footer/Footer";
import CategoryLeftNav from "../../../../../../components/TwoColumnLeftNav/CategoryLeftNav";
import {graphql} from "gatsby";
import Pagination from "../../../../../../components/Pagination/Pagination";
import useTranslationsCat from "../../../../../../components/useTranslationsCat";

const RsdWl = ({ data: { allMdx } }) => {
    const { rsdWl } = useTranslationsCat()
    return (
        <>
            <article className="category-page two-column-left-nav">
                <CategoryLeftNav />
                <section className="main-content">
                    <section className="main-content">
                        <div className="content-container">
                            <h2 className="large bold">{ rsdWl }</h2>
                            <Pagination posts={allMdx.edges} />
                        </div>
                    </section>
                </section>
            </article>
            <Footer />
        </>
    );
};

export default RsdWl;

export const query = graphql`
	query RsdWl($locale: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(product)/.*\\\\.mdx$/" }
				frontmatter: {  ignoreSearch: { ne: "true"}, categories: { regex: "/^rsd_wl$/" } }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: [frontmatter___sku], order: ASC }
		) {
			edges {
				node {
					frontmatter {
						sku
						name
						model
						price
						taxIncludedPrice
						productImages
						open_price
					}
					fields {
						locale
					}
					parent {
						... on File {
							relativeDirectory
						}
					}
				}
			}
		}
	}
`;
