import React from "react";
import Footer from "../../components/Footer/Footer";
import CategoryLeftNav from "../../components/TwoColumnLeftNav/CategoryLeftNav";
import {graphql} from "gatsby";
import Pagination from "../../components/Pagination/Pagination";
import useTranslations from "../../components/useTranslations";


const Category = ({data: {allMdx}}) => {
    const {category} = useTranslations()
    return (
        <>
            <article className="category-page two-column-left-nav">
                <CategoryLeftNav/>
                <section className="main-content">
                    <div className="content-container">
                        <h2 className="large bold">{category}</h2>
                        <Pagination posts={allMdx.edges} />
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    );
};

export default Category;

export const query = graphql`
  query category($locale: String!) {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/(akeneo)/.*\\\\.mdx$/"},
      fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___sku], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            sku
            name
            model
            price
            taxIncludedPrice
            productImages
            open_price
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`
